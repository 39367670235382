<template>
  <div>
    <Header></Header>
    <div class="content_pd">
      <transition>
        <router-view></router-view>
      </transition>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import otherBase from '@/plugins/otherBase'

export default {
  components: {
    Header,Footer
  },
  watch: {
    $route() {
      // location.reload()
      this.$router.go(0)
    }
  },
  created() {
    console.log('执行第三方代码---')
    otherBase.setJsCdn()
    otherBase.setCssCdn()
  },
}
</script>

<style scoped lang='scss'>
.content_pd {
  padding-top: 64px;
}
</style>
