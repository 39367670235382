<template>
	<div>
		<div class="web">
			<header>
        <!-- <template v-if="!$store.getters.GetIsH5">
				  <img src="~@/assets/img/Logo.png" alt="Logo" srcset="" @click="goPage('top')" class="logo" />
        </template>
        <template v-else> -->
				  <img src="~@/assets/img/h5-logo.png" alt="h5-logo" srcset="" @click="logoPush" class="logo" />
        <!-- </template> -->
				<div class="link_box">
					<div class="link h5_hid" @click="goPage('step01')">¿Cómo funciona?</div>
					<div class="link h5_hid" @click="goPage('step02')">¿Quiénes somos?</div>
					<div class="link h5_hid" @click="goPage('step03')">Calculadora</div>
					<a href="https://blog.tifi.com.mx/inicio" class="link h5_hid" target="_blank" style="color: white">Blog</a>
					<div class="link active" @click="goPage('step04')">Conoce más</div>
				</div>
        <div class="header_bottom">
          <el-dropdown trigger="click" @command="handleCommand">
            <i class="el-dropdown-link iconfont icon-gengduo header_bottom"></i>
            <el-dropdown-menu slot="dropdown" style="border: none;">
              <el-dropdown-item v-for="item in menuContent" :key="item.msg" :command="item.value" style="color: #1e3964">{{ item.msg }}</el-dropdown-item>
              <el-dropdown-item command="golink" style="color: #1e3964">Blog</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
			</header>
		</div>
	</div>
</template>
<script>
import { goScrollToPage } from '@/utils'
const img1 = require('../assets/img/close.svg')
const img2 = require('../assets/img/Frame.svg')
export default {
	data() {
		return {
			active: false,
      menuContent: [
        {
          value: 'step01',
          msg: '¿Cómo funciona?'
        },
        {
          value: 'step03',
          msg: 'Calculadora'
        },
        {
          value: 'step02',
          msg: '¿Quiénes somos?'
        }
      ]
		}
	},
	methods: {
    goPage(step) {
      if(this.$route.path === '/home') {
        return goScrollToPage(step)
      } else {
        return  this.$router.push({ path: '/home', query: { step }})
      }
    },
		GoToUrl(url) {
			this.$router.push(url)
			this.active = false
		},
		GoLink(url) {
			window.open(url)
		},
    handleCommand (command) {
      if(command === 'golink') {
        window.open('https://blog.tifi.com.mx/inicio')
      }else {
        this.goPage(command)
      }
    },
    logoPush() {
      if(this.$route.path === '/home') {
        this.goPage('top')
      } else {
        this.$router.push('/home')
      }
    }
	},
	filters: {
		GetUrl(active) {
			if (active) {
				return img1
			} else {
				return img2
			}
		}
	}
}
</script>
<style lang="scss" scoped>
.el-dropdown-menu {
  border: none !important;
  .el-dropdown-menu__item {
    border: none !important;
    padding: 15px 15px;
    font-size: 32px;
  }
}
.el-dropdown-menu__item:not(.is-disabled):hover{
  border: none !important;
  -webkit-tap-highlight-color: transparent!important;
  color: #fff;
}
// @media screen and (min-width: 1246px) {
.web {
  width: 100%;
  height: 75px;
  margin: 0 auto;
  display: block;
  position: relative;
  z-index: 20;
  position: fixed;
  left: 0;
  top: 0;
  background-color: var(--primary-blue);
  header {
    margin: 0 auto;
    width: 90%;
    height: 75px;
    padding: 5px 30px 0;
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    background-color: var(--primary-blue);
    .logo {
      width: 120px;
      height: 62px;
      display: block;
      cursor: pointer;
    }
    .link_box {
      padding-top: 15px;
      height: 38px;
      font-size: 16px;
      line-height: 38px;
      color: #ffffff;
      font-family: 'inter';
      .link {
        display: inline-block;
        vertical-align: top;
        margin-right: 40px;
        cursor: pointer;
        &.active {
          padding: 0 32px;
          background-color: var(--primary-red);
          border-radius: 24px;
          color: #ffffff;
        }
      }
      .link_img {
        padding: 10px 0;
        width: 24px;
        height: 24px;
        display: inline-block;
        vertical-align: top;
        margin-left: 12px;
        cursor: pointer;
      }
    }
    .header_bottom {
      display: none;
    }
  }
}
@media screen and (max-width: 1246px){
.web {
  left: 0;
  right:0;
  padding: 20px 0;
  header {
    .link_box {
      .active {
        padding: 5px 25px !important;
        font-size: 36px;
      }
    }
    .header_bottom {
      display: block;
      width: 10%;
      height: 100%;
      color: #fff;
      text-align: center;
      position: relative;
      .el-dropdown {
        border: none !important;
        -webkit-tap-highlight-color: transparent!important;
        width: 100%;
        height: 100%;
        .header_bottom {
          width: 100%;
          height: 100%;
          font-size: 50px;
          position: absolute;
          top: 65%;
          transform: translateY(-50%);
        }
      }
    }
  }
}
}
// }
</style>
