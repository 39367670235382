/**
 * 批量设置字体图标、动态js
 * @method cssCdn 动态批量设置字体图标
 * @method jsCdn 动态批量设置第三方js
 */
const cssCdnUrlList = [
	{ rel: 'preconnect', href: 'https://fonts.googleapis.com' },
	{ rel: 'stylesheet', href: '//at.alicdn.com/t/font_3177922_s1wn4oalstc.css' },
	{ rel: 'stylesheet', href: 'https://fonts.googleapis.com/css2?family=Sorts+Mill+Goudy&display=swap' },
	{
		rel: 'stylesheet',
		href: 'https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,800;0,900;1,600&display=swap'
	}
]

// 第三方 js url
const jsCdnUrlList = [
	// { src: 'https://cdn.bootcdn.net/ajax/libs/eruda/2.3.3/eruda.min.js', isDev: true }
	{ src: '//js.hsforms.net/forms/v2-legacy.js', page: '/home' }, // 表单
	{ src: '//js.hsforms.net/forms/v2.js', page: '/home' },
	{ src: '//js-na1.hs-scripts.com/20157671.js', id: 'hs-script-loader' } //客服
]

const otherBase = {
	// 动态批量设置字体图标
	setCssCdn: () => {
		if (cssCdnUrlList.length <= 0) return false
		cssCdnUrlList.map((v) => {
			const link = document.createElement('link')
			link.rel = v.rel
			link.href = v.href
			link.crossOrigin = 'crossOrigin'
			// if (v.crossOrigin)  link.crossOrigin=v.crossOrigin;
			document.getElementsByTagName('head')[0].appendChild(link)
		})
	},
	// 第三方 js url
	setJsCdn: () => {
		if (jsCdnUrlList.length <= 0) return false
		jsCdnUrlList.map((v) => {
			const script = document.createElement('script')
			script.src = v.src
			if (v.integrity) script.integrity = v.integrity
			if (v.crossOrigin) script.crossOrigin = v.crossOrigin
			if (v.id) script.id = v.id
			document.body.appendChild(script)
		})
	}
}

export default otherBase
