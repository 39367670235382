<template>
  <div class="footer">
    <div class="icons">
      <a target="_blank" href="https://www.linkedin.com/company/tifimex/"><img src="~@/assets/img/icons/LinkedIn.svg" alt="LinkedIn"></a>
      <a target="_blank" href="https://www.instagram.com/tifimx/"><img src="~@/assets/img/icons/Instagram.svg" alt="Instagram"></a>
      <a target="_blank" href=" https://www.facebook.com/tifimx/"><img src="~@/assets/img/icons/facebook.svg" alt="facebook"></a>
      <a target="_blank" href="https://twitter.com/tifimx"><img src="~@/assets/img/icons/twitter.svg" alt="twitter"></a>
      <a target="_blank" href="https://www.youtube.com/channel/UCtYw2vKQgyz0_6C55YGd8kg"><img src="~@/assets/img/icons/youtube.svg" alt="youtube"></a>
      <a target="_blank" href="https://play.google.com/store/apps/details?id=mx.com.tifi.credito"><img class="google_icon" src="~@/assets/img/whites_googleplay.png" alt="whites_googleplay"></a>
    </div>
    <div class="links">
      <span @click="$router.push('/preguntas')">Preguntas frecuentes</span>
      <span @click="$router.push('/condiciones')">Términos y condiciones</span>
      <span @click="$router.push('/avisoprivacidad')">Aviso de privacidad</span>
    </div>
    <div class="text">Copyright ©2021 TiFi. Todos los derechos reservados.</div>
  </div>
</template>

<script>
export default {
  props: {

  },
  data () {
    return {

    }
  },
  created () {

  },
}
</script>

<style scoped lang='scss'>
.footer {
  background-color: #1f3e65;
  .icons {
    padding: 30px;
    display: flex;
    justify-content: center;
    img {
      cursor: pointer;
      width: 80px;
      height: 80px;
    }
    .google_icon {
      width: 230px;
      height: 75px;
    }
  }
  .links {
    word-wrap: break-word;
    text-align: center;
    a {
      word-break: break-word;
      font-size: 24px;
      margin: 0 20px;
      color: #fff;
    }
    span {
      cursor: pointer;
      word-break: break-word;
      font-size: 24px;
      margin: 0 20px;
      color: #fff;
    }
  }
  .text {
    padding-top: 40px;
    padding-bottom: 20px;
    text-align: center;
    color: #fff;
    font-size: 20px;
  }
}
@media (min-width: 414px ) and (max-width: 1246px) {
  .footer {
    .icons {
      .google_icon {
        height: 80px;
      }
    }
    .links {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      a {
        font-size: 16px;
      }
    }
    .text {
      font-size: 16px;
    }
  }
}
</style>
